/**
 * Created by Matthieu on 04/02/2021.
 */
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'

var EventEmitter = require('events').EventEmitter;

import Vue3DRenderer from "./renderer/Vue3DRenderer";
import Vue3DDropShadow from "./shadow/Vue3DDropShadow";
import Vue3DAOShadow from "./shadow/Vue3DAOShadow";

import Vue3DControls from "./controls/Vue3DControls";
import Vue3DLabelRenderer from "./renderer/Vue3DLabelRenderer";
import Vue3DConfigurator from "./configurator/Vue3DConfigurator";



export default class Vue3D extends EventEmitter
{
    /**static renvoyées par les emiter*/
    /**
     * la scene est prête et la HDR chargée
     * @type {string}
     */
    static READY = "READY";
    /**
     * le model et ses textures sont chargés ou les textures demandées sont chargées
     * @type {string}
     */
    static LOAD_COMPLETE = "LOAD_COMPLETE";

    /**
     * le nom du container dans le dom
     * @type {string}
     */
    domContainer = null;
    /**
     * Force le cache des images
     * @type {boolean}
     */
    noCach = true;

    scene = null;
    camera = null;
    renderer = null;
    labelRenderer = null;
    controls = null;
    /**
     * Ombre ortho projetée au sol
     * @type {LampyreAOShadow}
     */
    aoShadow = null;
    /**
     * Le modele 3D
     */
    model = null;
    /**
     * Le root du modele 3D, permet de clean la scene plus facilement
     * @type {THREE.Group}
     */
    modelRoot = null;
    /**
     * Chemin vers la racine du projet
     * @type {string}
     */
    httpRootPath = "";

    configurator = null;

    constructor(_container = "", _httpRootPath) {

        super();

        let me = this;

        this.httpRootPath = _httpRootPath;

        this.domContainer = _container;
    }

    initScene()
    {
        let domParent = document.querySelector(this.domContainer);
        document.querySelector(this.domContainer).classList.add("loading");

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera( 30, domParent.clientWidth / domParent.clientHeight, 0.5, 3000 );
        this.renderer = new Vue3DRenderer(this.domContainer);
        this.renderer.loadHDR(this.scene, LayoutVars.envmap, this.sceneReady, this.onLoadProgress, this)
        //this.renderer.initSSAO(this.scene, this.camera);
        this.labelRenderer = new Vue3DLabelRenderer(this.domContainer);
        this.controls = new Vue3DControls(this.camera, this.renderer);

        let dropShadow = new Vue3DDropShadow(this.scene);
        this.aoShadowNear = new Vue3DAOShadow(this.scene, this.renderer, 5);
        this.aoShadowNear.shadow.blur = 0.15;
        this.aoShadowNear.shadow.darkness = 0.6;
        this.aoShadowNear.shadow.opacity = 0.1;

        this.startRenderer();
        window.addEventListener( 'resize', this.onWindowResize.bind(this), false );
    }

    initConfigurator()
    {
        this.configurator = new Vue3DConfigurator(this);
    }

    sceneReady(me)
    {
        document.querySelector(me.domContainer).classList.remove("loading");
        me.emit(Vue3D.READY);
    }

    onLoadProgress(me, label, percent)
    {
        //let container = document.querySelector(me.domContainer);
        //container.querySelector(".loader .info").textContent = label + " : " + percent + " % ";
    }

    onLoadError(msg)
    {

    }

    startRenderer()
    {
        this.render();
    }

    stopRenderer()
    {
        cancelAnimationFrame(this.rendererRequestFrameID);
    }

    resetCamera()
    {
        if(this.model) this.controls.resetCameraToObject(this.model);
    }


    initPreviewMode()
    {
        let me = this;

        me.controls.enabled = false;
        me.renderer.preserveDrawingBuffer = true;

        me.camera.position.x = me.camera.position.y = me.camera.position.z = 0;
        me.camera.rotation.x = me.camera.rotation.y = me.camera.rotation.z = 0;
        me.controls.target.set( 0, 0, 0 );

        me.camera.position.y = 93;

        document.querySelector(me.domContainer).classList.add("loading");

        if(!me.modelRoot)
        {
            me.modelRoot = new THREE.Group();
            me.scene.add( me.modelRoot );
        }

        //me.model = new THREE.Mesh(new THREE.PlaneGeometry(50, 50));
        me.model = new THREE.Mesh(new THREE.SphereGeometry(23, 128, 64));
        me.model.rotation.x = -Math.PI/2;

        me.model.traverse(function (child) {
            if (child.isMesh) {
                child.name = "preview";
                child.castShadow = true;
            }
        });

        me.modelRoot.add(me.model);
    }

    getImageFromRenderer()
    {
        let me = this;
        let imgData;
        me.render();
        try {
            let strMime = "image/png";
            imgData = me.renderer.domElement.toDataURL(strMime);

            return imgData;

        } catch (e) {
            console.log(e);
            return null;
        }
    }


    loadModel(modelFile, options = null, cbSuccess = null)
    {
        let me = this;

        document.querySelector(me.domContainer).classList.add("loading");

        me.stopRenderer();

        if(!me.modelRoot)
        {
            me.modelRoot = new THREE.Group();
            me.scene.add( me.modelRoot );
        }

        while ( me.modelRoot.children.length > 0 ) {

            const object = me.modelRoot.children[ 0 ];
            object.parent.remove( object );
        }

        const baseMaterial = new THREE.MeshStandardMaterial( {
            color: 0xffffff
        });

        const fbxLoader = new FBXLoader();
        fbxLoader.load(this.httpRootPath+"/"+modelFile+(me.noCach?"?no-cach="+Math.random():""),
            (object) => {
                me.model = object;
                object.traverse(function (child) {
                    if (child.isMesh) {
                        child.castShadow = true;
                        //child.receiveShadow = true;
                        child.material = baseMaterial;
                    }

                    if(options && options.labels)
                    {
                        if(child.isGroup && child.name.includes("label"))
                        {
                            if(me.labelRenderer && options.labels) {
                                for (const [key, value] of Object.entries(options.labels)) {
                                    if(child.name.includes(key)){
                                        me.labelRenderer.addLabel(me.modelRoot, child.position, value);
                                        break;
                                    }
                                }

                            }
                        }
                    }

                });

                if(options && options.materials) me.setMaterials(options.materials);
                else {
                    document.querySelector(me.domContainer).classList.remove("loading");
                    me.emit(Vue3D.LOAD_COMPLETE);
                }

                me.modelRoot.add(me.model);
                me.aoShadowNear.render();

                me.startRenderer();

                this.controls.resetCameraToObject(this.model, this.scene, (options && options.animate != null)?options.animate:true);

                if(cbSuccess) cbSuccess();
            },
            (xhr) => {
                //me.onLoadProgress(me, "loading model", Math.round(xhr.loaded / xhr.total * 100));
            },
            (error) => {
                console.log(error);
            }
        )


    }

    setMaterials(materialList)
    {
        let me = this;

        document.querySelector(me.domContainer).classList.add("loading");
        //me.onLoadProgress(me, "loading material", 0);

        let totalMaps = Object.entries(materialList).length*4;
        let mapLoadedCount = 0;

        for (const [key, value] of Object.entries(materialList)) {
            me.setMaterial(key, value, ()=>{

                mapLoadedCount++;
                //me.onLoadProgress(me, "loading material", Math.round(mapLoadedCount/totalMaps*100));

                if(mapLoadedCount === totalMaps-1) {
                    document.querySelector(me.domContainer).classList.remove("loading");

                    me.emit(Vue3D.LOAD_COMPLETE);
                }
            });
        }
    }

    setMaterial(meshName, materialInfo, cbMapLoaded)
    {
        let me = this;

        let map_list = [];
        map_list[0] = materialInfo.albedoMap;
        map_list[1] = materialInfo.metallicMap;
        map_list[2] = materialInfo.normalMap;
        map_list[3] = materialInfo.roughnessMap;

        let textures_loader = [];

        map_list.forEach(function(map)
        {
            let loader = new THREE.TextureLoader().load( me.httpRootPath+"/"+map+(me.noCach?"?no-cach="+Math.random():""), cbMapLoaded, null, cbMapLoaded);
            loader.colorSpace = THREE.LinearSRGBColorSpace;
            loader.wrapS = THREE.RepeatWrapping;
            loader.wrapT = THREE.RepeatWrapping;
            textures_loader.push(loader);
        });

        let mat = new THREE.MeshPhysicalMaterial( {
            name:materialInfo.name,
            color: 0xffffff,
            //transparent: true,
            //opacity:0.5,
            //refractionRatio: 0.5,
            //ior: 0.5,
            map: textures_loader[0],
            metalnessMap: textures_loader[1],
            metalness:1,
            normalMap:textures_loader[2],
            //normalScale: new THREE.Vector2(0.05, 0.05),
            roughnessMap:textures_loader[3],
            roughness:1,
            envMapIntensity:1
            //side:THREE.DoubleSide
        });

        me.model.traverse(function (child) {
            if (child.isMesh && child.name.includes(meshName)) {
                child.material = mat;
            }
        });
    }

    render()
    {
        if(this.configurator && this.configurator.stats) this.configurator.stats.begin();

        if(this.controls) this.controls.update();
        this.renderer.update(this.scene, this.camera);
        if(this.labelRenderer) this.labelRenderer.render( this.scene, this.camera );

        this.rendererRequestFrameID = requestAnimationFrame( this.render.bind(this) );

        if(this.configurator && this.configurator.stats) this.configurator.stats.end();
    }


    onWindowResize()
    {
        let domParent = document.querySelector(this.domContainer);

        if(this.camera)
        {
            this.camera.aspect = domParent.clientWidth / domParent.clientHeight;
            this.camera.updateProjectionMatrix();
        }

        if(this.labelRenderer) this.labelRenderer.setSize( domParent.clientWidth, domParent.clientHeight );
        if(this.renderer) this.renderer.resize();

        this.controls.resetCameraToObject(this.model, this.scene);
    }
}