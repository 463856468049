import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import gsap from "gsap";

export default class Vue3DControls extends OrbitControls {

    constructor(_camera, _renderer) {

        super(_camera, _renderer.domElement);
        this.camera = _camera;

        this.camera.position.x = 0;
        this.camera.position.y = 0;
        this.camera.position.z = 0;

        this.minDistance = 50;

        this.enableDamping = true;
        this.dampingFactor = 0.08;
        this.maxPolarAngle = Math.PI/2;// + Math.PI/20;
        this.mouseButtons = {
            LEFT: THREE.MOUSE.ROTATE,
            MIDDLE: THREE.MOUSE.PAN,
            RIGHT: THREE.MOUSE.PAN
        }

        this.target.set( 0, 60, 0 );
    }

    resetCameraToObject(object, scene, animate = false) {

        if(!object) return;

        let bb = new THREE.Box3().setFromObject(object, true);
        let size = bb.getSize(new THREE.Vector3());
/*
        const geometry = new THREE.BoxGeometry(size.x, size.y, size.z);
        geometry.translate(0, size.y / 2, 0);
        const wireframe = new THREE.WireframeGeometry(geometry);

        const line = new THREE.LineSegments(wireframe);
        line.material.depthTest = false;
        line.material.opacity = 1;
        line.material.transparent = true;
        scene.add(line);
*/
        const vFOV = this.camera.fov * Math.PI / 180;
        let distance = 0;

        let hypoZ = Math.hypot(size.x, size.z);
        let hypoY = Math.hypot(size.x, size.y);

        if (hypoY*1.3 / (2 * Math.tan(vFOV / 2)) > hypoZ * 1.3 / (2 * Math.tan(this.vfovToHfov(vFOV, this.camera.aspect) / 2))) {
            distance = hypoY*1.3 / (2 * Math.tan(vFOV / 2));
        } else {
            distance = hypoZ * 1.3 / (2 * Math.tan(this.vfovToHfov(vFOV, this.camera.aspect) / 2));
        }

        this.minDistance = distance / 4;
        this.maxDistance = distance * 1.5;

        let target = new THREE.Object3D();
        this.camera.position.x = target.position.x = 0;
        this.camera.position.y = target.position.y = size.y / 2;
        this.camera.position.z = target.position.z = 0;

        if (animate)
        {
            target.rotation.x = -50 * Math.PI / 360;
            target.rotation.y = 60 * Math.PI / 360;
            target.translateZ(distance);

            this.camera.rotation.x = -50 * Math.PI / 360;
            this.camera.rotation.y = 30 * Math.PI / 360;
            this.camera.translateZ(distance);

            let me = this;
            gsap.to(this.camera.position, {
                duration: 1.5,
                x: target.position.x,
                y: target.position.y,
                z: target.position.z
            });
        } else {
            this.camera.rotation.x = -50 * Math.PI / 360;
            this.camera.rotation.y = 60 * Math.PI / 360;
            this.camera.translateZ(distance);
        }

        this.target.set( 0, size.y/2, 0 );
    }



    /**
     * Convert vertical field of view to horizontal field of view, given an aspect
     * ratio. See https://arstechnica.com/civis/viewtopic.php?f=6&t=37447
     *
     * @param vfov - The vertical field of view.
     * @param aspect - The camera aspect ratio, which is generally width/height of the viewport.
     * @returns - The horizontal field of view.
     */
    vfovToHfov(vfov, aspect) {
        const {tan, atan} = Math
        return atan(aspect * tan(vfov / 2)) * 2
    }
}