import Api from "./Api";

export default class Api3dvue extends Api{

    constructor(){
        super();
        let me=this;
    }


    /**
     * Pour récupérer un model
     * @param {String} name
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    getFile3d(name,cbSuccess,cbError){
        this._call(
            "3dvue","get-file3d",
            {
                name:name,
            },
            cbSuccess,
            cbError
        );
    }

    /**
     * Pour récupérer une liste de material
     * @param {Object} materialList
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    getMaterialList(materialList,cbSuccess,cbError){
        this._call(
            "3dvue","get-materialList",
            {
                materialList:JSON.stringify(materialList),
            },
            cbSuccess,
            cbError
        );
    }


    /**
     * Pour saver une image base64 en thumbnail du material
     * @param {String} materialName
     * @param {String} base64
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    saveBase64asThumbnailMaterial(materialName, base64,cbSuccess,cbError){
        this._call(
            "3dvue","save-base64asthumbnail-material",
            {
                materialName:materialName,
                base64image:base64
            },
            cbSuccess,
            cbError
        );
    }


    /**
     * Pour saver une image base64 en thumbnail du model
     * @param {String} modelName
     * @param {String} base64
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    saveBase64asThumbnailModel(modelName, base64,cbSuccess,cbError){
        this._call(
            "3dvue","save-base64asthumbnail-model",
            {
                modelName:modelName,
                base64image:base64
            },
            cbSuccess,
            cbError
        );
    }

}