import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min'
import Stats from "stats.js";
import {EventEmitter} from "events";
import * as THREE from 'three';

export default class Vue3DConfigurator extends EventEmitter {

    vue3d = null;
    gui = null;
    stats = null;

    api = {
        displayStats: true,
        displayEnvMap:false,
        map:"",
        mapExposure:1,
        toneMapping:"None",
        model:"",
        mesh:"",
        material:"",
        url:""
    }

    modelId = null;
    materialFolder = null;
    materialCtrl = null;
    urlCtrl = null;

    constructor(_vue3d) {
        super();

        let me = this;
        me.vue3d = _vue3d;
        me.gui = new GUI();

        me.statsFolder();
        me.hdrFolder();
        me.modelFolder();
    }

    statsFolder()
    {
        let me = this;

        me.stats = new Stats();
        me.stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
        document.body.appendChild( me.stats.dom );

        const folder = this.gui.addFolder( 'Stats');
        folder.add(me.api, 'displayStats').onChange(function (display) {
            if(display) document.body.appendChild( me.stats.dom );
            else document.body.removeChild( me.stats.dom );
        });
    }

    hdrFolder()
    {
        let me = this;

        const folder = me.gui.addFolder( 'HDR');
        folder.add( me.api, 'map' ).options( LayoutVars.envmaps.map(a => a.name) ).onChange(function (selection) {
            const mapInfo = LayoutVars.envmaps.find(({ name }) => name === selection);
            let path = me.vue3d.httpRootPath+"/"+mapInfo.path+(this.noCach?"?no-cach="+Math.random():"");
            me.vue3d.renderer.loadHDR(me.vue3d.scene, path, ()=>{
                if(me.vue3d.scene.background != null ) me.vue3d.scene.background = me.vue3d.scene.environment;
            }, null, null);
        });

        folder.add(me.api, 'displayEnvMap').onChange(function (display) {
            if(display) me.vue3d.scene.background = me.vue3d.scene.environment;
            else me.vue3d.scene.background = null;
        });

        folder.add(me.api, 'mapExposure', 0, 2, 0.01).onChange((value)=>{
            me.vue3d.renderer.toneMappingExposure = value;
        });


        const toneMappingOptions = {
            None: THREE.NoToneMapping,
            Linear: THREE.LinearToneMapping,
            Reinhard: THREE.ReinhardToneMapping,
            Cineon: THREE.CineonToneMapping,
            ACESFilmic: THREE.ACESFilmicToneMapping
        };
        console.log(Object.keys(toneMappingOptions))

        folder.add(me.api, "toneMapping").options( Object.keys(toneMappingOptions) ).onChange((value)=>{
            me.vue3d.renderer.toneMapping = toneMappingOptions[value];
        });
    }

    modelFolder()
    {
        let me = this;

        const folder = me.gui.addFolder( 'Models');
        this.urlCtrl = folder.add(me.api, 'url');


        folder.add( me.api, 'model' ).options( LayoutVars.models.map(a => a.name) ).onChange(function (selection) {

            me.modelId = selection
            me.urlCtrl.setValue(LayoutVars.iframeURL+"?model="+me.modelId);

            const modelInfo = LayoutVars.models.find(({ name }) => name === selection);

            me.vue3d.loadModel(modelInfo.path, null, me.meshList.bind(me));

            if(me.materialFolder) me.materialFolder.destroy();
        });
    }


    meshList()
    {
        let me = this;
        me.api.mesh = "";
        let mesh = [""];
        me.vue3d.model.traverse(function (child) {
            if (child.isMesh) {
                let pattern = "mesh_";
                if(!child.name.includes(pattern)) return;
                let identifier = pattern+child.name.slice(pattern.length, pattern.length+3);

                if(mesh.includes(identifier)) return;
                mesh.push(identifier);
            }
        });

        mesh.sort();

        me.materialFolder = me.gui.addFolder( 'Materials' );
        let meshCtrl = me.materialFolder.add( this.api, 'mesh' ).options( mesh );

        me.materialList();

        meshCtrl.onChange( function (selection) {
            me.api.material = "";
            me.materialCtrl.setValue("");

            me.vue3d.model.traverse(function (child) {
                if(child.isMesh) {
                    if(child.name.includes(me.api.mesh))
                    {
                        me.materialCtrl.setValue(child.material.name);
                    }
                }
            });
        });
    }

    materialList()
    {
        let me = this;

        me.api.material = "";

        me.materialCtrl = me.materialFolder.add( me.api, 'material' ).options( LayoutVars.materials.map(a => a.name) );
        me.materialCtrl.onChange( function (value) {
            if(me.api.mesh !== "" && me.api.material !== "")
            {
                let key = me.api.mesh;
                const materialInfo = LayoutVars.materials.find(({ name }) => name === me.api.material);
                let data = {[key] : materialInfo}
                me.vue3d.setMaterials(data);

                //list material setup
                let mesh = [];
                me.vue3d.model.traverse(function (child) {
                    if (child.isMesh) {
                        let pattern = "mesh_";
                        if(!child.name.includes(pattern)) return;
                        let identifier = pattern+child.name.slice(pattern.length, pattern.length+3);

                        if(mesh.some(obj => obj["id"] === identifier)) return;
                        mesh.push({id:identifier, mesh:child});
                    }
                });

                let options = {materials:{}};
                mesh.forEach((el)=>{
                    console.log(el.id+" : " + el.mesh.material.name);
                    if(el.mesh.material.name !== "")
                    {
                        options.materials[el.id] = el.mesh.material.name;
                    }
                });

                console.log(JSON.stringify(options));

                me.urlCtrl.setValue(LayoutVars.iframeURL+"?model="+me.modelId+"&options="+JSON.stringify(options));
            }
        });
    }

}